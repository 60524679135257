/** @jsx jsx */
import {css, jsx} from "@emotion/react"
import {theme} from "../components/styles/Theme"
import {CandidateProfileBlock as ICandidateProfileBlock} from "../types/content-blocks"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {IconProp} from "@fortawesome/fontawesome-svg-core"
import {faCheck, faXmark} from "@fortawesome/free-solid-svg-icons"

export const CandidateProfile = (content: ICandidateProfileBlock) => {
  const getBullets = (bullets: {text: string}[], icon: IconProp) => {
    return bullets.map((bullet, index) => {
      const text = bullet.text
        .replace("*", "<strong>")
        .replace("*", "</strong>")
      return (
        <div key={index} className="bullet-point">
          <FontAwesomeIcon icon={icon} />
          <p className="medium" dangerouslySetInnerHTML={{__html: text}}></p>
        </div>
      )
    })
  }

  const link = () => {
    const text = content.callToActionText?.split("?")
    return (
      <p>
        {text?.map((block, index) => {
          return index == 0 ? (
            <span key={index}>{block}? </span>
          ) : (
            <a href={content.callToActionLink} key={index} className="link">
              {block}→
            </a>
          )
        })}
      </p>
    )
  }

  return (
    <div css={CandidateStyles}>
      <h1>{content.title}</h1>
      <div className="lists">
        {content.prosAndCons.map((section, index) => {
          return (
            <div key={index} className="list">
              <h3>{section.title}</h3>
              {getBullets(section.bullets, index == 0 ? faCheck : faXmark)}
              {index == 1 && <div className="cta">{link()}</div>}
            </div>
          )
        })}
      </div>
    </div>
  )
}

const CandidateStyles = css`
  margin: auto;
  margin-top: 10rem;

  @media screen and (${theme.breakpoints.desktopDown}) {
    margin-top: 8rem;
  }

  h1 {
    margin-bottom: 6rem;
    text-align: center;
    font-weight: 600;

    @media screen and (${theme.breakpoints.smallDown}) {
      margin-bottom: 4rem;
    }
  }
  .lists {
    display: flex;
    justify-content: center;

    .cta {
      padding: 2.5rem 3rem;
      text-align: center;

      a {
        font-size: 1.4rem;
        font-weight: 300;
        text-decoration: none;
        color: ${theme.colors.fontcolors.orange};
      }
    }

    @media screen and (${theme.breakpoints.largeDesktopDown}) {
      display: flex;
      flex-wrap: wrap;
    }

    .list {
      @media screen and (${theme.breakpoints.largeDesktopDown}) {
        width: 70%;
        margin: auto;
        margin-bottom: 4rem;

        @media screen and (${theme.breakpoints.mediumDown}) {
          width: 85%;
        }

        @media screen and (${theme.breakpoints.mobileDown}) {
          width: 100%;
        }
      }

      h3 {
        text-align: center;
        margin-bottom: 2rem;
        font-weight: 500;
      }

      .bullet-point {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 1rem;
        padding: 2.5rem 3rem;
        min-width: 53rem;
        border-radius: 5rem;
        background-color: #bef2fe;

        @media screen and (${theme.breakpoints.mediumDown}) {
          padding: 1.5rem 3rem;
        }

        @media screen and (${theme.breakpoints.desktopDown}) {
          min-width: auto;
        }

        p {
          margin: 0rem;

          strong {
            font-weight: 400;
          }
        }

        svg {
          margin-right: 2rem;
          font-size: 2rem;
          color: ${theme.colors.buttoncolors.blue};
        }
      }
    }
  }
`
